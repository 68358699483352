import { useMemo } from 'react';

import { Box } from 'components/Box';
import { AnchorButton } from 'components/Button/AnchorButton';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { RichText } from 'components/Text/RichText';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import {
  CmsApiSplitBlockAppearance,
  CmsApiSplitBlockText,
} from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { CmsButtonLoadable } from 'modules/cms/components/Button/CmsButtonLoadable';
import { colors, gutterWidth } from 'theme/theme';

type Props = {
  section: CmsApiSplitBlockText;
  appearance: CmsApiSplitBlockAppearance;
  index: number;
  blockId: string;
  imageTextBlock: boolean;
  showBackgroundImage: boolean;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsSplitContentTextContent({
  appearance,
  blockId,
  blockIndex,
  imageTextBlock,
  index,
  pageUpdated,
  section,
  showBackgroundImage,
}: Props) {
  const showTextBox = ['FULL_WIDTH', 'OVERLAPPING'].includes(appearance);

  const boxShadow = useMemo(() => {
    if (showTextBox) {
      return [
        !imageTextBlock && '0 0 6px rgba(0,0,0,0.3)',
        null,
        '0 0 6px rgba(0,0,0,0.3)',
      ];
    }

    if (showBackgroundImage) {
      return ['0 4px 9px rgba(0, 0, 0, 0.1)', null, 'none'];
    }

    return undefined;
  }, [imageTextBlock, showBackgroundImage, showTextBox]);

  const hideButton =
    section.data.button &&
    section.data.button.type === 'CMS_PAGE' &&
    !section.data.button.data.pageTitle;

  return (
    <Box
      data-qa-id={`split-content-section-${index}`}
      // @ts-expect-error TS(2322): Type 'false | 1' is not assignable to type 'Respon... Remove this comment to see the full error message
      zIndex={showTextBox && 1}
      width={[1, null, 1 / 2]}
      px={[imageTextBlock ? '24px' : gutterWidth / 4, null, gutterWidth / 2]}
      py={[
        imageTextBlock ? gutterWidth / 2 : gutterWidth / 4,
        null,
        gutterWidth / 2,
      ]}
      key={blockId}
    >
      <Box
        height="100%"
        width="100%"
        bg={colors.white}
        // @ts-expect-error TS(2322): Type '(string | false | null)[] | undefined' is no... Remove this comment to see the full error message
        boxShadow={boxShadow}
        borderRadius={showTextBox ? 12 : 24}
      >
        <Box
          position="relative"
          px={[gutterWidth / 2, null, gutterWidth]}
          pr={showTextBox ? gutterWidth / 3 : [null, null, 0]}
          py={[
            imageTextBlock && !showBackgroundImage ? 0 : gutterWidth / 2,
            null,
            ['OVERLAPPING', 'FULL_WIDTH'].includes(appearance)
              ? gutterWidth
              : 0,
          ]}
          height="100%"
        >
          {section.data.label && (
            <HeadingSmall color={colors.lightContentGrey}>
              {section.data.label}
            </HeadingSmall>
          )}
          {section.data.headline && (
            <Box
              display="block"
              mb={24}
              color={colors.mediumContentGrey}
              textDecoration="none"
            >
              <RichTextHeadline>{section.data.headline}</RichTextHeadline>
            </Box>
          )}
          <RichText
            analyticsData={{
              eventName: 'CMS Block',
              eventProperties: {
                block_position: blockIndex,
                block_type: 'Split Content',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: section.data.html,
            }}
            styleAs="body"
            tag="div"
          />
          {section.data.button && !hideButton && (
            <Box mt={[24, null, 36]}>
              <CmsButtonLoadable
                buttonData={section.data.button}
                eventProperties={{
                  block_position: blockIndex,
                  block_type: 'Split Content',
                  button_text: section.data.button.text,
                  page_updated: pageUpdated,
                }}
                blockId={blockId}
                sectionIndex={index}
              >
                <AnchorButton variant="primary" />
              </CmsButtonLoadable>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
